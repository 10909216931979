<template>
  <Transition
    enter-active-class="transition-all"
    leave-active-class="transition-all"
    leave-to-class="opacity-0 -translate-y-3"
    enter-from-class="opacity-0 -translate-y-3"
  >
    <slot />
  </Transition>
</template>
